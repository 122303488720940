<script lang="ts" setup>
const { t } = useI18n();
const { isAdmin } = storeToRefs(useUserStore());
const onboardingShown = useLocalStorage("onboarding-shown", false);

const show = computed(() => !onboardingShown.value && isAdmin.value);

function handleClick() {
  window.open(`${useRuntimeConfig().public.onboardingArticleUrl}`, "_blank");
  onboardingShown.value = true;
}
</script>

<template>
  <div v-auto-animate>
    <div
      v-if="show"
    >
      <UDivider
        class="mb-3"
        type="dashed"
      />
      <UAlert
        color="primary"
        variant="subtle"
        class="text-xs"
        :title="t('user.onboarding.title')"
        :description="t('user.onboarding.description')"
        :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'primary', variant: 'link', padded: false }"
        :actions="[{ label: t('user.onboarding.readMore'), padded: false, click: handleClick }]"
        :ui="{
          title: 'text-xs font-semibold',
          description: 'text-xs',
        }"
        @close="onboardingShown = true"
      />
    </div>
  </div>
</template>
