<script setup lang="ts">
const { t } = useI18n();
const { organization, avatarUrl: userAvatarUrl, user } = storeToRefs(useUserStore());
const { signOut } = useAuthentication();

const avatarUrl = computed(() => organization.value?.logoUrl
  || useAuth().data.value?.user?.image
  || userAvatarUrl.value);

const name = computed(() => organization.value?.name
  ?? user.value?.fullName
  ?? user.value?.emailorUserName);

const items = computed(() => [
  [{
    slot: "account",
    label: "",
    disabled: true,
  }],
  [{
    label: t("welcome.user.signOut"),
    icon: "i-heroicons-arrow-left-on-rectangle",
    click: async () => {
      await signOut();
    },
  }],
]);

const NuxtPicture = resolveComponent("NuxtPicture");
</script>

<template>
  <UDropdown
    mode="click"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
    :open-delay="100"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="name"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar
            :as="NuxtPicture"
            :src="avatarUrl"
            alt="User avatar"
            size="2xs"
            crossorigin="anonymous"
          />
        </template>

        <template #trailing>
          <UIcon
            name="i-heroicons-ellipsis-vertical"
            class="ml-auto size-5"
          />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>
          {{ t("welcome.user.signedIn") }}
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ user?.emailorUserName }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
